import Glide from '@glidejs/glide';
import GLightbox from "glightbox";
import Sidebar from './sidebar';
import Slider from './slider';
import ChangeContent from './changeContent';
import ScrollSpy from './scrollSpy';
import map from './map';
/**
 * Created by Michał on 11.05.2017.
 */
function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    // content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}
function toggleBlogMenu(){
    let menu = document.querySelector('#blog-menu');
    let blog_mask = document.querySelector('#blog-mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-blog-hamburger').classList.toggle("active");
    document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    blog_mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let hrs = document.querySelectorAll('hr');
if(hrs.length > 0){
    hrs.forEach(hr => {
       let sep = document.createElement('div');
       sep.setAttribute('class', hr.getAttribute('class'));
       sep.classList.add('separator');
       hr.parentElement.replaceChild(sep, hr);
    });
}

let links = document.querySelectorAll('a');
if(links.length > 0){
    links.forEach(link => {
       let href = link.getAttribute('href');
       if(href.substr(0,1) === '#'){
           link.addEventListener('click', event => {
               event.preventDefault();
               let adr = link.getAttribute('href');
               adr = adr.substr(1);
               let element = document.getElementById(adr);
               console.log(href, element);
               element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
           })
       }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
        }
    });
}

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 200;
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }

    let header = document.querySelector('.js-header');
    let body = document.querySelector('body');
    if (header !== null) {
        if(window.scrollY > 10) {
            header.classList.add('header--scrolled');
        } else {
            header.classList.remove('header--scrolled');
        }
    }

    // if (header !== null) {
    //     let navigation_ele ment = header.querySelector('.navigation').querySelector('.menu-item-has-children');
    //     if (navigation_element !== null) {
    //         navigation_element.addEventListener('mouseover', (e) => {
    //             header.classList.add('header--scrolled-plus');
    //         });
    //         navigation_element.addEventListener('mouseout', (e) => {
    //             header.classList.remove('header--scrolled-plus');
    //         });
    //     }
    // }
});
window.addEventListener('DOMContentLoaded', () => {
    let ms = null;
    let main_slider = document.querySelector('.js-homepage-slider');
    if (main_slider !== null) {
        ms = new Glide(main_slider, {
            type: 'carousel',
            perView: 1,
            autoplay: 3500,
            animationDuration: 1,
            animationTimingFunc: 'ease-in-out',
            breakpoints: {
                400: {
                    autoplay: 4500,
                },
                600: {
                    autoplay: 3500,
                }
            }
        }).mount();
    }

    // let contact_form = document.querySelector('.js-contact-form');
    // if (contact_form !== null) {
    //     new Form(contact_form, {
    //         action: '/wp-admin/admin-ajax.php',
    //         data: element => {
    //             let data = {
    //                 first_name: '',
    //                 city: '',
    //                 email: '',
    //                 phone: '',
    //                 subject: '',
    //                 message: '',
    //                 client: '',
    //                 architect: '',
    //                 action: 'nt_send_contact_form'
    //             };
    //             if (element.querySelector('[name="first_name"]') != null) data.first_name = element.querySelector('[name="first_name"]').value;
    //             if (element.querySelector('[name="city"]') != null) data.city = element.querySelector('[name="city"]').value;
    //             if (element.querySelector('[name="email"]') != null) data.email = element.querySelector('[name="email"]').value;
    //             if (element.querySelector('[name="phone"]') != null) data.phone = element.querySelector('[name="phone"]').value;
    //             if (element.querySelector('[name="subject"]') != null) data.subject = element.querySelector('[name="subject"]').value;
    //             if (element.querySelector('[name="message"]') != null) data.message = element.querySelector('[name="message"]').value;
    //             return data;
    //         },
    //         beforeSubmit: (element, hashed) => {
    //             let loader = element.querySelector('.loader__background');
    //             loader.style.display = 'flex';
    //             loader.querySelector('.loader').style.display = 'block';
    //         },
    //         success: (element, response) => {
    //             if (response.status === 'success') {
    //                 let loader = element.querySelector('.loader__background');
    //                 let successMessage = loader.querySelector('.loader__success');
    //                 successMessage.innerHTML = 'Dziękujemy za wypełnienie formularza.';
    //                 loader.querySelector('.loader').style.display = 'none';
    //                 element.querySelector('[name="first_name"]').value = '';
    //                 element.querySelector('[name="city"]').value = '';
    //                 element.querySelector('[name="email"]').value = '';
    //                 element.querySelector('[name="phone"]').value = '';
    //                 element.querySelector('[name="subject"]').value = '';
    //                 element.querySelector('[name="message"]').value = '';
    //                 successMessage.style.display = 'block';
    //                 setTimeout(() => {
    //                     loader.style.display = 'none';
    //                     successMessage.style.display = 'none';
    //                     successMessage.innerHTML = '';
    //                 }, 8000);
    //             }
    //         },
    //         error: (element, response) => {
    //             let loader = element.querySelector('.loader__background');
    //             let errorMessage = loader.querySelector('.loader__error');
    //             errorMessage.innerHTML = 'Przepraszamy wystąpił problem. Odśwież stronę i spróbuj ponownie.';
    //             loader.querySelector('.loader').style.display = 'none';
    //             errorMessage.style.display = 'block';
    //             setTimeout(() => {
    //                 loader.style.display = 'none';
    //                 errorMessage.style.display = 'none';
    //                 errorMessage.innerHTML = '';
    //             }, 8000);
    //         }
    //     });
    // }
    let lightbox = GLightbox();
    window.initMap = map;
});

let sidebarSticky = document.querySelectorAll('.js-sidebar');

if(sidebarSticky.length > 0){
    sidebarSticky.forEach(container => {
        new Sidebar(container);
    })
}

let sliders = document.querySelectorAll('.js-slider');

if(sliders.length > 0){
    sliders.forEach(slider => {
        new Slider(slider);
    })
}

let changeContentsContainer = document.querySelectorAll('.js-changeContent');

if(changeContentsContainer.length > 0){
    changeContentsContainer.forEach(element => {
        new ChangeContent(element);
    })
}

let scrollContainer = document.querySelectorAll('.js-navigation');

if(scrollContainer.length > 0){
    scrollContainer.forEach(element => {
        new ScrollSpy(element);
    })
}

function WHCreateCookie(name, value, days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    var expires = "; expires=" + date.toGMTString();
    document.cookie = name+"="+value+expires+"; path=/";
}
function WHReadCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

window.onload = WHCheckCookies;

function WHCloseCookiesWindow() {
    WHCreateCookie('cookies_accepted', 'T', 365);
    document.getElementById('cookies-message-container').removeChild(document.getElementById('cookies-message'));
}
window.WHCloseCookiesWindow = WHCloseCookiesWindow;
function WHCheckCookies() {
    if(WHReadCookie('cookies_accepted') != 'T') {
        var message_container = document.createElement('div');
        message_container.id = 'cookies-message-container';
        var html_code = '<div id="cookies-message" style="color:#555555;  padding: 10px 0px; font-size: 12px; line-height: 22px; border-bottom: 1px solid #161616; text-align: center; position: fixed; bottom: 0px; background-color: #c8c3be; width: 100%; z-index: 99999;">'+window.cookie_text+'<a href="javascript:WHCloseCookiesWindow();" id="accept-cookies-checkbox" name="accept-cookies" style="background-color: #A97948;  padding: 1px 10px 3px 10px; color: #FFF; border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px; display: inline-block; margin-left: 10px; text-decoration: none; cursor: pointer;">'+window.cookie_button+'</a></div>';
        message_container.innerHTML = html_code;
        document.body.appendChild(message_container);
    }
}
