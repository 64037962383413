class ChangeContent {
    constructor(selector){
        this.selector = selector;
        this.personsContainer = selector.querySelector('[data-element="persons"]');
        this.descriptionsContainer = selector.querySelector('[data-element="descriptions"]');

        this.persons = this.personsContainer.querySelectorAll('[data-id]');
        this.descriptions = this.descriptionsContainer.querySelectorAll('[data-id]');

        this._personClick = this._personClick.bind(this);

        this.isActive = 0;

        this.persons.forEach(person => {
            person.addEventListener('click', this._personClick);
        })

        this.mediaQueries = window.matchMedia("(max-width: 700px)");

        if(this.mediaQueries.matches){
            this.descriptions.forEach(description => {
                let descriptionID = description.getAttribute('data-id');
                this.persons.forEach(person=> {
                    let personID = person.getAttribute('data-id');
                    if(descriptionID == personID){
                        person.appendChild(description);
                    }
                })
            })
        }
    }

    _personClick(event) {
        event.preventDefault();
        let currentPerson = event.currentTarget.getAttribute('data-id');
        console.log(this.isActive);
        if(!this.isActive){
            this.isActive = 1;
            this.descriptions.forEach(description =>{
                let descriptionId = description.getAttribute('data-id');
                if(descriptionId === currentPerson){
                    description.classList.add('active');
                    this.descriptionsContainer.style.height = description.offsetHeight + "px";
                }
            })
        } else {
            let currentActiveDescription = this.descriptionsContainer.querySelector('.active').getAttribute('data-id');
            this.descriptions.forEach(description => {
                let currentDescription = description.getAttribute('data-id');
                if(currentPerson === currentDescription){
                    description.classList.add('active');
                    this.descriptionsContainer.style.height = description.offsetHeight + "px";
                } else if (currentDescription === currentActiveDescription){
                    description.classList.remove('active');
                } else if (currentPerson === currentActiveDescription){
                    description.classList.remove('active');
                }
            })

            if(currentPerson === currentActiveDescription){
                this.descriptionsContainer.style.height = "0px";
                this.descriptions.forEach(description=>{
                    description.classList.remove('active');
                })
                this.isActive = 0;
            }

        }

    }
}

export default ChangeContent;