class Slider {
    constructor(selector){
        this.selector = selector;
        this.imageContainer = selector.querySelector('[data-element="images"]');
        this.contentContainer = selector.querySelector('[data-element="content"]');
        this.navigation = selector.querySelector('[data-element="navigation"]');

        this.slideCount = this.imageContainer.querySelectorAll('[data-id]').length;

        let currentActiveSlide = this.imageContainer.querySelector('.active');
        if(currentActiveSlide !== null) this.currentSlide = parseInt(currentActiveSlide.getAttribute('data-id'));
        else {
            this.currentSlide = 1;
            this._changeSlide();
        }


        this._navigationClick = this._navigationClick.bind(this);
        this._nextSlide = this._nextSlide.bind(this);
        this._mouseOverSlider = this._mouseOverSlider.bind(this);
        this._mouseOutSlider = this._mouseOutSlider.bind(this);

        if(this.slideCount > 0){
            let navigationElements = this.navigation.querySelectorAll('[data-id]');

            navigationElements.forEach(navigationElement =>{
                navigationElement.addEventListener('click', this._navigationClick);
            });

            this.intervalChange = setInterval(this._nextSlide, 3000);

            this.selector.addEventListener('mouseover', this._mouseOverSlider);

            this.selector.addEventListener('mouseout', this._mouseOutSlider);
        }
    }

    _mouseOverSlider() {
        clearInterval(this.intervalChange);
    }

    _mouseOutSlider() {
        this.intervalChange = setInterval(this._nextSlide, 3000);
    }

    _navigationClick(event) {
        event.preventDefault();
        this.currentSlide = event.target.getAttribute('data-id');
        this._changeSlide();
    }

    _changeSlide() {
        let currentActiveSlide = this.imageContainer.querySelector('.active');
        let currentActiveNavigation = this.navigation.querySelector('.active');
        let currectActiveContent = this.contentContainer.querySelector('.active');

        let cid = 0;
        if(currentActiveSlide !== null) {
            cid = parseInt(currentActiveSlide.getAttribute('[data-id]'));
        }
        if(cid !== this.currentSlide){
            let images = this.imageContainer.querySelectorAll('[data-id]');
            let navigations = this.navigation.querySelectorAll('[data-id]');
            let contents = this.contentContainer.querySelectorAll('[data-id]');

            images.forEach(image => {
               let imageAttribute =image.getAttribute('data-id');
               if(this.currentSlide == imageAttribute){
                   image.classList.add('active');
               }
            });

            navigations.forEach(navigation => {
                let navigationAttribute = navigation.getAttribute('data-id');
                if(this.currentSlide == navigationAttribute){
                    navigation.classList.add('active');
                }
            });

            contents.forEach(content => {
                let contentAttribute = content.getAttribute('data-id');
                if(this.currentSlide == contentAttribute){
                    content.classList.add('active');
                }
            });

            currentActiveNavigation.classList.remove('active');
            currentActiveSlide.classList.remove('active');
            currectActiveContent.classList.remove('active');
        }
    }



    _nextSlide() {
        this.currentSlide++;
        if (this.currentSlide > this.slideCount) this.currentSlide = 1;
        this._changeSlide();
    }
}

export default Slider;