class scrollSpy {
    constructor(container){
        this.container = container;
        this.navContainer = container.querySelector('[data-navigation]');
        this.contentsContainer = container.querySelector('[data-content]');
        this.navElements = this.navContainer.querySelectorAll('a');
        this.contents = this.contentsContainer.querySelectorAll('[data-id]');

        this._goToElement = this._goToElement.bind(this);

        this.navElements.forEach(element => {
            element.addEventListener('click', this._goToElement);
        })
    }

    _goToElement(event){
        event.preventDefault();
        let currentNavElement = event.currentTarget.getAttribute('href');

        this.contents.forEach(content => {
            let currentContentId = content.getAttribute('data-id');
            if(currentNavElement === currentContentId) {
                content.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            }
        })
    }
}

export default scrollSpy;